<template>
	<div class="app-box">
		<div class="title-box">
			<div class="line"></div>
			<p class="title">用户管理</p>
		</div>
		<a-divider style="margin-left: -10px" />
		<a-form-model class="form-search" :colon="false" layout="inline" :model="query" labelAlign="left"
			@submit="handleSearch" @submit.native.prevent>
			<a-row>
				<a-form-model-item style="float: right; margin-right: 0">
					<a-button type="primary" html-type="submit"> 查询 </a-button>
					<a-button @click="Reset">重置</a-button>
				</a-form-model-item>
				<a-form-model-item label="类型">
					<a-select placeholder="请选择会员、普通" v-model="query.is_member">
						<a-select-option :value="0" :key="0">
							普通
						</a-select-option>
						<a-select-option :value="1" :key="1">
							会员
						</a-select-option>
					</a-select>
				</a-form-model-item>
				<a-form-model-item label="等级">
					<a-select placeholder="请选择会员等级" v-model="query.level_id">
						<a-select-option :value="item.level_id" v-for="item in levelList" :key="item.level_id">
							{{ item.name }}
						</a-select-option>
					</a-select>
				</a-form-model-item>
				<a-form-model-item label="姓名">
					<a-input v-model="query.name" placeholder="请输入姓名"></a-input>
				</a-form-model-item>
				<a-form-model-item label="会员ID">
					<a-input v-model="query.member_card" placeholder="请输入会员ID"></a-input>
				</a-form-model-item>
				<a-form-model-item label="手机号">
					<a-input v-model="query.phone" placeholder="请输入手机号"></a-input>
				</a-form-model-item>
			</a-row>
		</a-form-model>
		<a-row class="table-action-list" style="margin-top: 20px">
			<a-button type="primary" @click="add" style="margin-left: 10px">+新增会员</a-button>
		</a-row>
		<a-table class="main-table" :pagination="{
			current: query.page,
			total: pagetotal,
			showTotal: (pagetotal, range) => `共 ${pagetotal} 条数据`,
		}" @change="listChange" :columns="columns" :data-source="data" rowKey="equipment_id" style="margin-top: 14px"
			v-viewer>
			<span slot="member_card" slot-scope="text, record">
				{{ record.member_card != "" ? record.member_card : '非会员' }}
			</span>
			<span slot="level_id" slot-scope="text, record">
				{{ getName(levelList, record.level_id, "level_id", "name") }}
			</span>
			<!-- <span slot="wenjuan" slot-scope="text, record">
				<div v-if="record.is_wenjuan !== 1">
					<a>-</a>
				</div>
				<div v-if="record.is_wenjuan === 1">
					<a @click="toWenjuan(record)">查看</a>
				</div>
			</span> -->
			<span slot="package" slot-scope="text, record">
				<a @click="toPackage(record)">查看套餐</a>
			</span>
			<span slot="action" slot-scope="text, record">
				<a-space>
					<a @click="toEdit(record)">编辑</a>
					<div v-if="record.status == 1">
						<a-popconfirm title="确认停用该用户" ok-text="是" cancel-text="否" @confirm="del(record.customer_id)">
							<a style="color: #E0352B">停用</a>
						</a-popconfirm>
					</div>
					<div v-if="record.status != 1">
						<a style="color: #959292">已停用</a>
					</div>
				</a-space>
			</span>
		</a-table>

		<!-- 抽屉1 添加/修改 -->
		<a-drawer :title="(customer_id == null ? '新增' : '编辑') + '用户'" :width="720" :visible="visible1"
			:body-style="{ paddingBottom: '80px' }" @close="onClose1">

			<a-form-model ref="ruleForm" :model="customerForm" :rules="rules" :label-col="labelCol"
				:wrapper-col="wrapperCol">
				<a-form-model-item ref="name" label="用户姓名" prop="name">
					<a-input v-model="customerForm.name" @blur="() => {
						$refs.name.onFieldBlur();
					}
						" placeholder="请输入" />
				</a-form-model-item>
				<a-form-model-item v-if="customer_id == null || customer_id == ''" ref="phone" label="手机号" prop="phone">
					<a-input v-model="customerForm.phone" @blur="() => {
						$refs.name.onFieldBlur();
					}
						" placeholder="请输入" />
				</a-form-model-item>
				<a-form-model-item label="会员等级" prop="level_id">
					<a-select placeholder="请选择会员等级" v-model="customerForm.level_id">
						<a-select-option :value="item.level_id" v-for="item in levelList" :key="item.level_id">
							{{ item.name }}
						</a-select-option>
					</a-select>
				</a-form-model-item>
				<a-form-model-item label="性别" prop="gender">
					<a-radio-group v-model="customerForm.gender">
						<a-radio :value="1">
							男
						</a-radio>
						<a-radio :value="2">
							女
						</a-radio>
					</a-radio-group>
				</a-form-model-item>
				<a-form-model-item label="出生日期" required prop="birthday_str">
					<a-date-picker v-model="customerForm.birthday_str" type="date" placeholder="请选择出生日期"
						style="width: 100%;" valueFormat="YYYY-MM-DD" />
				</a-form-model-item>
				<a-form-model-item ref="id_card" label="身份证号" prop="id_card">
					<a-input v-model="customerForm.id_card" @blur="() => {
						$refs.name.onFieldBlur();
					}
						" placeholder="请输入" />
				</a-form-model-item>
				<a-form-model-item label="标签" prop="tag">
					<div class="tag-input-container">
						<div class="tag-container">
							<a-tag v-for="(tag, index) in customerForm.tag" :key="tag" closable @close="handleClose(tag)"
								style="margin-bottom: 8px">
								{{ tag }}
							</a-tag>
						</div>
						<a-input v-model="inputValue" ref="input" @pressEnter="handleInputConfirm"
							@blur="handleInputConfirm" class="input-tag" placeholder="请在这儿输入标签后点击添加标签" />
						<a-button @click="handleInputConfirm" class="add-button">添加标签</a-button>
					</div>
				</a-form-model-item>
				<a-form-model-item prop="area" label="省市区">
					<a-cascader class="cascader" v-if="addrCode.length === 3" :default-value="addrCode"
						:field-names="{ label: 'name', value: 'adcode', children: 'districts' }" :load-data="loadSsq"
						change-on-select :options="ssqData" placeholder="请选择省市区" @change="ssqChange" />
				</a-form-model-item>
				<a-form-model-item ref="address" label="邮寄地址" prop="address">
					<a-input v-model="customerForm.address" @blur="() => {
						$refs.name.onFieldBlur();
					}
						" placeholder="请输入" />
				</a-form-model-item>
				<a-form-model-item label="备注说明" prop="remark">
					<a-input v-model="customerForm.remark" type="textarea" />
				</a-form-model-item>
			</a-form-model>

			<div :style="{
				position: 'absolute',
				right: 0,
				bottom: 0,
				width: '100%',
				borderTop: '1px solid #e9e9e9',
				padding: '10px 16px',
				background: '#fff',
				textAlign: 'right',
				zIndex: 1,
			}
				">
				<a-button :style="{ marginRight: '8px' }" @click="onClose1">
					取消
				</a-button>
				<a-button type="primary" @click="submitForm">
					确定
				</a-button>
			</div>
		</a-drawer>


		<!-- 抽屉2 查看问卷 -->
		<a-drawer title="查看问卷" :width="720" :visible="visible2" :body-style="{ paddingBottom: '80px' }" @close="onClose2">
			<div :style="{
				position: 'absolute',
				right: 0,
				bottom: 0,
				width: '100%',
				borderTop: '1px solid #e9e9e9',
				padding: '10px 16px',
				background: '#fff',
				textAlign: 'right',
				zIndex: 1,
			}
				">
				<a-button :style="{ marginRight: '8px' }" @click="onClose2">
					取消
				</a-button>
				<a-button type="primary" @click="onClose2">
					确定
				</a-button>
			</div>
		</a-drawer>

		<!-- 抽屉3 查看套餐 -->
		<a-drawer title="会员套餐" :width="550" :visible="visible3" :body-style="{ paddingBottom: '80px' }" @close="onClose3">
			<!-- <a-divider /> -->
			<div :value="item.level_id" v-for="item in packageList" :key="item.level_id">
				<a-descriptions :column="1">
					<a-descriptions-item label="卡号" :span="1">
						{{ item.card_no }}
					</a-descriptions-item>
					<a-descriptions-item label="购买类型">
						{{ item.type == 1 ? "自购" : (item.type == 2 ? "赠送" : (item.type == 3 ? "系统发放" : "激活")) }}
					</a-descriptions-item>
					<a-descriptions-item label="购买时间">
						{{ item.create_at_str }}
					</a-descriptions-item>
					<a-descriptions-item label="金额">
						{{ item.sale_money }}元
					</a-descriptions-item>
					<a-descriptions-item label="服务套餐">
						{{ item.name }}
					</a-descriptions-item>
					<a-descriptions-item label="使用期限">
						{{ item.begin_time_str + " 至 " + item.end_time_str }}
					</a-descriptions-item>
					<a-descriptions-item label="服务项目" :span="1">
						<div :value="item2.level_id" v-for="item2 in item.services" :key="item2.level_id">
							<template>{{ item2.name }} &nbsp;￥{{ item2.money }}元&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</template>
							已预约{{ item2.use_time }}次&nbsp;|<template style="float: right;" v-if="item2.number !== 0">
								&nbsp;剩余{{ item2.number -
									item2.use_time
								}}次</template>
							<template style="float: right;" v-if="item2.number === 0">
								&nbsp;不限次</template>
						</div>
					</a-descriptions-item>
				</a-descriptions>
				<a-divider />
			</div>
			<div :style="{
				position: 'absolute',
				right: 0,
				bottom: 0,
				width: '100%',
				borderTop: '1px solid #e9e9e9',
				padding: '10px 16px',
				background: '#fff',
				textAlign: 'right',
				zIndex: 1,
			}
				">
				<a-button :style="{ marginRight: '8px' }" @click="onClose3">
					取消
				</a-button>
				<a-button type="primary" @click="onClose3">
					确定
				</a-button>
			</div>
		</a-drawer>
		<a-drawer title="问卷" :width="550" :visible="visible4" :body-style="{ paddingBottom: '80px' }"
			@close="visible4 = false">
			<!-- <a-divider /> -->
			<div class="ques-box">
				<div class="ques-item" v-for="item in answer" :key="item.questionId">
					<div class="question">{{ item.question.label }}</div>
					<div v-if="item.question.type === 'radio'" class="radio-group">
						<div class="radio" v-for="(i, j) in item.question.options" :key="j"
							:class="{ checked: item.answer === '' + j }">
							{{ i.label }}
						</div>
					</div>
					<div v-if="item.question.type === 'checkbox'" class="radio-group">
						<div class="radio" v-for="(i, j) in item.question.options" :key="j"
							:class="{ checked: item.answer.includes('' + j) }">
							{{ i.label }}
						</div>
					</div>
					<div v-if="item.question.type === 'input'" class="radio checked">{{ item.answer }}</div>
				</div>
			</div>
			<div :style="{
				position: 'absolute',
				right: 0,
				bottom: 0,
				width: '100%',
				borderTop: '1px solid #e9e9e9',
				padding: '10px 16px',
				background: '#fff',
				textAlign: 'right',
				zIndex: 1,
			}
				">
				<a-button :style="{ marginRight: '8px' }" @click="visible4 = false">
					取消
				</a-button>
				<a-button type="primary" @click="visible4 = false">
					确定
				</a-button>
			</div>
		</a-drawer>
	</div>
</template>

<script>
import { domain } from '@/siteInfo.js'
import { ssqdata } from '@/utils/ssqdata.js'
import question from '@/utils/基础问卷调查题目'
import axios from 'axios'

export default {
	data() {
		let validateClass = (rule, value, callback) => {
			if (value) {
				callback(); // 通过验证
			} else {
				callback(new Error('请选择分类')); // 验证失败，返回错误信息
			}
		};
		return {
			ssqData: [...ssqdata],
			query: {
				page: 1,
				limit: 10
			},
			customer_id: null,
			pagetotal: 0,
			checkValue: [],
			packageList: [],
			tags: ['Tag1', 'Tag2', 'Tag3'],
			inputValue: '',
			checkOptions: [
				{
					label: '使用中',
					value: 1
				},
				{
					label: '已完成',
					value: 2
				},
				{
					label: '已退款',
					value: 3
				}
			],
			addrCode: [],
			visible1: false,
			visible2: false,
			visible3: false,
			visible4: false,
			labelCol: { span: 4 },
			wrapperCol: { span: 14 },
			other: '',
			customerForm: {
				name: '',
				tag: []
			},
			curParent: [],
			rules: {
				name: [
					{ required: true, message: '请输入用户姓名', trigger: 'blur' },
				],
				phone: [
					{ required: true, message: '请输入手机号', trigger: 'blur' },
				],
				level_id: [{ required: true, validator: validateClass, message: '请选择会员等级', trigger: 'change' }],
				gender: [{ required: true, validator: validateClass, message: '请选择性别', trigger: 'change' }],
				birthday_str: [
					{ required: true, message: '请选择出生日期', trigger: 'blur' },
				],
				id_card: [
					{ required: true, message: '请选择身份证号', trigger: 'blur' },
				],
				tag: [
					{ required: true, message: '请填写标签', trigger: 'blur' },
				],
				area: [{
					required: true,
					message: '填选择省市区',
					trigger: 'blur'
				}],
			},
			answer: [],
			columns: [
				{
					title: '会员ID',
					dataIndex: 'member_card',
					scopedSlots: {
						customRender: 'member_card'
					}
				},
				{
					title: '姓名',
					dataIndex: 'name'
				},
				{
					title: '手机号',
					dataIndex: 'phone'
				},
				{
					title: '会员等级',
					scopedSlots: {
						customRender: 'level_id'
					}
				},
				// {
				// 	title: '问卷',
				// 	scopedSlots: {
				// 		customRender: 'wenjuan'
				// 	}
				// },
				{
					title: '会员套餐',
					scopedSlots: {
						customRender: 'package'
					}
				},
				{
					title: '注册时间',
					dataIndex: 'create_at_str'
				},
				{
					title: '操作',
					scopedSlots: {
						customRender: 'action',
					},
				},
			],
			data: [],
			levelList: [],
			selectedRowKeys: []
		}
	},
	created() {
		this.getList()
		this.getLevelList()
	},
	methods: {
		toWenjuan(record) {
			this.$post(domain + '/admin/v1/customer/wenjuanInfo', { "customer_id": record.customer_id }).then(res => {
				if (res.code === 0) {
					res.data.answer.map(item => {
						item.question = question.find(i => i.questionId === item.questionId)
					})
					this.answer = res.data.answer
					this.visible4 = true
				} else {
					this.$message.error(res.msg)
				}
				// this.$post(domain + '/admin/v1/wenjuan/info', { "customer_wenjuan_id": res.data.customer_wenjuan_id }).then(({ data }) => {
				// 	console.log(data)
				// })
			})
		},
		toPackage(record) {
			this.$post(domain + '/admin/v1/customer/memberPackage', { "customer_id": record.customer_id }).then(res => {
				if (res.code === 0) {
					if (res.data.length > 0) {
						this.packageList = res.data
						this.visible3 = true
					} else {
						this.$message.warn("暂未绑定套餐")
					}
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		onClose3() {
			this.visible3 = false
		},
		onClose2() {
			this.visible2 = false
		},
		handleClose(removedTag) {
			const tags = this.customerForm.tag.filter(tag => tag !== removedTag);
			this.customerForm.tag = tags;
		},
		submitForm() {
			let action = "";
			if (this.customer_id != null) {
				action = "update"
				this.customerForm.customer_id = this.customer_id
			} else {
				action = "add"
			}
			this.$post(domain + '/admin/v1/customer/' + action, this.customerForm).then(res => {
				if (res.code === 0) {
					this.$message.success(res.msg)
					this.visible1 = false
					this.initForm()
					this.getList()
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		handleInputConfirm() {
			const inputValue = this.inputValue;
			console.log(inputValue)
			let tags = JSON.parse(JSON.stringify(this.customerForm.tag));
			if (inputValue && tags.indexOf(inputValue) === -1) {
				tags = [...tags, inputValue];
			}
			this.customerForm.tag = tags;
			console.log("customerForm", this.customerForm.tag)
			this.inputValue = '';
		},
		Reset() {
			this.query = {
				page: 1,
				limit: this.query.limit
			}
			this.getList()
		},
		handleChange(value) {
			console.log(`Selected: ${value}`);
		},
		popupScroll() {
			console.log('popupScroll');
		},
		getName(arr, id, idName, lableName) { //通过id获取数组名称
			if (id || id == '0') {
				const index = arr.findIndex((role) => role[idName] === id)
				return index >= 0 ? arr[index][lableName] : undefined
			} else {
				return undefined
			}
		},
		del(customer_id) {
			this.$post(domain + '/admin/v1/customer/delete', { "customer_id": customer_id }).then(res => {
				if (res.code === 0) {
					this.$message.success("停用成功")
					this.getList()
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		add() {
			this.addrCode = ['', '', '']
			this.visible1 = true;
		},
		toEdit(record) {
			this.customer_id = record.customer_id
			this.$post(domain + '/admin/v1/customer/info', { customer_id: this.customer_id }).then(res => {
				if (res.code === 0) {
					this.customerForm = res.data
					console.log("性别", [this.customerForm.pro_code, this.customerForm.city_code, this.customerForm.area_code])

					this.addrCode = [this.customerForm.pro_code, this.customerForm.city_code, this.customerForm.area_code]
					this.ssqData = [...ssqdata]
					this.curParent = ssqdata

					this.initLoad(0)
					this.visible1 = true;
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		onClose1() {
			this.visible1 = false;
			this.initForm()
		},
		initForm() {
			this.customer_id = null
			this.ssqData = [...ssqdata]
			this.addrCode = []
			this.customerForm = {
				name: '',
				tag: []
			}
		},
		getList() {
			this.$post(domain + '/admin/v1/customer/list', this.query).then(res => {
				if (res.code === 0) {
					this.data = res.data.list
					this.pagetotal = res.data.total
				} else {
					this.$message.error(res.msg)
				}
			})
		},

		getLevelList() {
			this.$post(domain + '/admin/v1/customerLevel/listNoPage').then(res => {
				if (res.code === 0) {
					this.levelList = res.data
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		listChange(e) {
			this.query.page = e.current
			this.getList()
		},
		handleSearch(query) {
			this.query.page = 1
			this.customer_id = null
			this.getList()
			console.log(query)
		},
		initLoad(ind) {
			console.log("current", this.curParent)
			console.log('dizhi code', this.addrCode)
			let code = this.addrCode[ind]
			console.log("code", code)
			for (let i = 0; i < this.curParent.length; i++) {
				let item = this.curParent[i]
				if (code == item.adcode) {
					const targetOption = item
					targetOption.loading = true
					let subdistrict =
						targetOption.level == 'province'
							? 1
							: targetOption.level == 'city'
								? 2
								: targetOption.level == 'district'
									? 3
									: 4
					axios
						.get('https://restapi.amap.com/v3/config/district', {
							params: {
								keywords: targetOption.adcode,
								subdistrict: subdistrict,
								key: 'ce7404ac71c28a7893cae3ccc5517da0'
							}
						})
						.then(res => {
							for (let i = 0; i < res.data.districts[0].districts.length; i++) {
								let item = res.data.districts[0].districts[i]
								delete item.districts
								item.isLeaf = item.level == 'district'
							}
							console.log("请求下级地址", res.data.districts[0].districts)
							targetOption.districts = res.data.districts[0].districts
							this.ssqData = [...this.ssqData]
							console.log('医院回显', this.ssqData)
							targetOption.loading = false
							this.curParent = res.data.districts[0].districts
							if (ind + 2 != this.addrCode.length) {
								this.initLoad(ind + 1)
							}
						})
						.catch(err => {
							console.log(err)
						})
					break
				}
			}
		},
		loadSsq(selectedOptions) {
			const targetOption = selectedOptions[selectedOptions.length - 1]
			targetOption.loading = true
			let subdistrict =
				targetOption.level == 'province'
					? 1
					: targetOption.level == 'city'
						? 2
						: targetOption.level == 'district'
							? 3
							: 4
			axios
				.get('https://restapi.amap.com/v3/config/district', {
					params: {
						keywords: targetOption.adcode,
						subdistrict: subdistrict,
						key: 'ce7404ac71c28a7893cae3ccc5517da0'
					}
				})
				.then(res => {
					console.log(res)
					let districts = res.data.districts[0].districts
					console.log(res.data.districts[0].adcode == 810000)
					for (let i = 0; i < districts.length; i++) {
						let item = districts[i]
						delete item.districts
						item.isLeaf = item.level == 'district'
					}
					targetOption.districts = districts
					targetOption.loading = false
					this.ssqData = [...this.ssqData]
				})
				.catch(err => {
					console.log(err)
				})
		},
		ssqChange(e, opt) {
			this.customerForm.pro_code = opt[0] ? opt[0].adcode : ''
			this.customerForm.pro = opt[0] ? opt[0].name : ''
			this.customerForm.city_code = opt[1] ? opt[1].adcode : ''
			this.customerForm.city = opt[1] ? opt[1].name : ''
			this.customerForm.area_code = opt[2] ? opt[2].adcode : ''
			this.customerForm.area = opt[2] ? opt[2].name : ''

			console.log('省市区修改：', this.addrCode)
		}
	}
}
</script>

<style lang="less" scoped>
.title-box {
	display: flex;
	align-items: center;
	margin-left: 8px;

	.line {
		width: 5px;
		height: 14px;
		background-color: #4e80f8;
	}

	.title {
		color: #383f50;
		font-size: 16px;
		margin-left: 5px;
	}
}

.img-qr {
	height: 30px;
	cursor: pointer;
}
</style>

<style lang="less">
.form-search {
	.ant-form-item-label {
		width: 70px;
	}
}

.ques-box {
	.question {
		font-weight: bold;
		margin-bottom: 10px;
	}

	.radio-group {
		display: flex;
	}

	.radio {
		margin-right: 10px;
		margin-bottom: 10px;

		&.checked {
			color: #1677FF;
		}
	}
}
</style>
